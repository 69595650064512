import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { InputLabel, ListSubheader, MenuItem, Select } from "@material-ui/core";
import BaseNameFragment from "./BaseNameFragment";

const EnvironmentFragment = (props) => {
  const { id, index, setValue } = props;
  const [selected, setSelected] = useState("prod");

  useEffect(() => {
    setValue(id, selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BaseNameFragment draggableId={id} index={index} title="Environment">
      <InputLabel id={`${id}-label`}></InputLabel>
      <Select
        id={`${id}-value`}
        labelId={`${id}-label`}
        value={selected}
        onChange={(e) => {
          setValue(id, e.target.value);
          setSelected(e.target.value);
        }}
      >
        <ListSubheader>Proof-of-concept</ListSubheader>
        <MenuItem value="poc">poc</MenuItem>

        <ListSubheader>Demo</ListSubheader>
        <MenuItem value="demo">demo</MenuItem>

        <ListSubheader>Development</ListSubheader>
        <MenuItem value="development">development</MenuItem>
        <MenuItem value="dev">dev</MenuItem>
        <MenuItem value="d">d</MenuItem>

        <ListSubheader>Testing</ListSubheader>
        <MenuItem value="testing">testing</MenuItem>
        <MenuItem value="test">test</MenuItem>
        <MenuItem value="t">t</MenuItem>

        <ListSubheader>Quality Assurance</ListSubheader>
        <MenuItem value="qa">qa</MenuItem>
        <MenuItem value="q">q</MenuItem>

        <ListSubheader>Staging</ListSubheader>
        <MenuItem value="staging">staging</MenuItem>
        <MenuItem value="stag">stag</MenuItem>
        <MenuItem value="s">s</MenuItem>

        <ListSubheader>Production</ListSubheader>
        <MenuItem value="production">production</MenuItem>
        <MenuItem value="prod">prod</MenuItem>
        <MenuItem value="p">p</MenuItem>
      </Select>
    </BaseNameFragment>
  );
};

EnvironmentFragment.propTypes = {
  children: PropTypes.node,
  id: PropTypes.any.isRequired,
  index: PropTypes.any.isRequired,
  setValue: PropTypes.func.isRequired,
};

export default EnvironmentFragment;
