import data from "../data/azure_resources";

const ResourceService = {
  getAll: () => {
    return data.sort((a, b) => {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 0;
    });
  },
};

export default ResourceService;
