import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { TextField } from "@material-ui/core";
import BaseNameFragment from "./BaseNameFragment";

const RandomFragment = (props) => {
  const { id, index, setValue } = props;
  const [length, setLength] = useState(6);

  useEffect(() => {
    setValue(id, genHex(length));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const genHex = (size) =>
    [...Array(size)]
      .map(() => Math.floor(Math.random() * 16).toString(16))
      .join("");

  const handleChange = (e) => {
    if (e.target.value > 0 && e.target.value <= 16) {
      setLength(e.target.value);
      setValue(id, genHex(parseInt(e.target.value)));
    }
  };

  return (
    <BaseNameFragment draggableId={id} index={index} title="Random string">
      <TextField
        id={`${id}-value`}
        type="number"
        label="Length"
        variant="outlined"
        size="small"
        aria-posinset={index}
        onChange={handleChange}
        value={length}
      />
    </BaseNameFragment>
  );
};

RandomFragment.propTypes = {
  children: PropTypes.node,
  id: PropTypes.any.isRequired,
  index: PropTypes.any.isRequired,
  setValue: PropTypes.func.isRequired,
};

export default RandomFragment;
