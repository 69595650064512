import React from "react";
import PropTypes from "prop-types";
import { Draggable } from "react-beautiful-dnd";
import { Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";

const useStyles = makeStyles((theme) => ({
  root: {
    userSelect: "none",
    padding: theme.spacing(2),
    margin: 0,
    marginRight: theme.spacing(2),
    minWidth: theme.spacing(20),
    width: theme.spacing(20),
  },
  default: {
    backgroundColor: theme.palette.primary.dark,
  },
  dragging: {
    backgroundColor: theme.palette.primary.main,
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  drag: {
    marginLeft: -theme.spacing(0.75),
  },
}));

const BaseNameFragment = (props) => {
  const classes = useStyles();
  const { children, draggableId, index, title, ...other } = props;

  return (
    <Draggable draggableId={draggableId} index={index} {...other}>
      {(provided, snapshot) => (
        <Paper
          style={provided.draggableProps.style}
          className={[
            classes.root,
            snapshot.isDragging ? classes.dragging : classes.default,
          ].join(" ")}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <form noValidate autoComplete="off">
            <Grid container direction="row">
              <Grid item>
                <DragIndicatorIcon className={classes.drag} />
              </Grid>
              <Grid>
                <Typography className={classes.title}>{title}</Typography>
              </Grid>
            </Grid>
            {children}
          </form>
        </Paper>
      )}
    </Draggable>
  );
};

BaseNameFragment.propTypes = {
  children: PropTypes.node,
  draggableId: PropTypes.any.isRequired,
  index: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
};

export default BaseNameFragment;
