import React, { useEffect, useState } from "react";
import { Badge, makeStyles, Tooltip, Typography } from "@material-ui/core";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { DataGrid } from "@material-ui/data-grid";
import {
  Check as CheckIcon,
  ErrorOutline as ErrorIcon,
} from "@material-ui/icons";
import clsx from "clsx";
import ResourceTypeFragment from "../../components/ResourceTypeFragment";
import ApplicationFragment from "../../components/ApplicationFragment";
import EnvironmentFragment from "../../components/EnvironmentFragment";
import LocationFragment from "../../components/LocationFragment";
import InstanceFragment from "../../components/InstanceFragment";
import ResourceService from "../../services/ResourceService";
import RandomFragment from "../../components/RandomFragment";
import DataGridToolbar from "../../components/DataGridToolbar";

const useStyles = makeStyles((theme) => ({
  fragmentContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  dataContainer: {
    width: "100%",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  dataGrid: {
    border: 0,
    "& .MuiDataGrid-main": {
      marginTop: theme.spacing(2),
    },
  },
  highlight: {
    backgroundColor: theme.palette.grey[800],
  },
  highlightLeft: {
    borderLeft: "2px solid",
    borderLeftColor: theme.palette.grey[700],
  },
  highlightRight: {
    borderRight: "2px solid",
    borderRightColor: theme.palette.grey[700],
  },
  droppable: {
    minHeight: theme.spacing(18),
    display: "flex",
    overflow: "auto",
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    borderWidth: 1,
    borderStyle: "dashed",
    borderColor: theme.palette.grey[700],
  },
  title: {
    marginTop: theme.spacing(2),
  },
}));

const availableItems = [{ id: "random" }];

const defaultItems = [
  { id: "resource-type", valueGetter: (context) => context.slug },
  { id: "application" },
  { id: "environment" },
  { id: "location" },
  { id: "instance" },
];

const Resources = (props) => {
  const classes = useStyles();
  const [available, setAvailable] = useState(availableItems);
  const [selected, setSelected] = useState(defaultItems);
  const [resources, setResources] = useState([]);

  const definitions = ResourceService.getAll();

  /** @type import("@material-ui/data-grid").GridColDef[] */
  const resourceColumns = [
    { field: "group", headerName: "Group", width: 240 },
    { field: "type", headerName: "Asset type", width: 290 },
    { field: "scope", headerName: "Scope", width: 170 },
    {
      field: "name",
      headerName: "Name",
      width: 350,
      cellClassName: clsx(classes.highlight, classes.highlightLeft),
    },
    {
      field: "errors",
      headerName: "Valid",
      width: 100,
      cellClassName: clsx(classes.highlight, classes.highlightRight),
      renderCell: (params) =>
        params.value && params.value.length ? (
          <Badge badgeContent={params.value.length} color="error">
            <Tooltip
              title={
                <React.Fragment>
                  {params.value.map((error, index) => (
                    <Typography key={`error-${index}`}>{error}</Typography>
                  ))}
                </React.Fragment>
              }
            >
              <ErrorIcon color="error" />
            </Tooltip>
          </Badge>
        ) : (
          <CheckIcon color="primary" />
        ),
    },
  ];

  useEffect(() => {
    const newResources = definitions.map((definition) => {
      let name = selected
        .map((item) =>
          item.valueGetter ? item.valueGetter(definition) : item.value
        )
        .join(definition.dashes ? "-" : "");

      let errors = [];
      if (name.length < definition.length.min)
        errors.push(
          `Name too short (${name.length}), min length is ${definition.length.min}`
        );
      if (name.length > definition.length.max)
        errors.push(
          `Name too long (${name.length}), max length is ${definition.length.max}`
        );
      if (!RegExp(definition.regex).test(name))
        errors.push(
          `Name does not conform to the regex rules '${definition.regex}'`
        );

      return {
        id: definition.name,
        group: definition.group,
        type: definition.name,
        scope: definition.scope,
        name: name,
        errors: errors,
      };
    });
    setResources(newResources);
  }, [definitions, selected]);

  const setItemValue = (id, value) => {
    setSelected(
      selected.map((item) => {
        if (item.id === id) item.value = value;
        return item;
      })
    );
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const remove = (list, startIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    return [result, removed];
  };

  const append = (list, endIndex, items) => {
    const result = Array.from(list);
    result.splice(endIndex, 0, items);
    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    if (result.source.droppableId !== result.destination.droppableId) {
      const [newSource, removed] = remove(
        result.source.droppableId === "available" ? available : selected,
        result.source.index
      );
      const newDestination = append(
        result.destination.droppableId === "available" ? available : selected,
        result.destination.index,
        removed
      );
      result.source.droppableId === "available"
        ? setAvailable(newSource)
        : setSelected(newSource);
      result.destination.droppableId === "available"
        ? setAvailable(newDestination)
        : setSelected(newDestination);
    } else {
      const newDestination = reorder(
        result.destination.droppableId === "available" ? available : selected,
        result.source.index,
        result.destination.index
      );
      result.destination.droppableId === "available"
        ? setAvailable(newDestination)
        : setSelected(newDestination);
    }
  };

  return (
    <div>
      <div className={classes.fragmentContainer}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Typography variant="h5" className={classes.title}>
            Available fragments
          </Typography>
          <Droppable droppableId="available" direction="horizontal">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                className={classes.droppable}
                // style={getDroppableStyle(snapshot.isDraggingOver)}
                {...provided.droppableProps}
              >
                {available &&
                  available.map((item, index) => {
                    if (item.id === "resource-type")
                      return (
                        <ResourceTypeFragment
                          key={`fragment-${item.id}`}
                          id={item.id}
                          index={index}
                          setValue={setItemValue}
                        />
                      );
                    if (item.id === "application")
                      return (
                        <ApplicationFragment
                          key={`fragment-${item.id}`}
                          id={item.id}
                          index={index}
                          setValue={setItemValue}
                        />
                      );
                    if (item.id === "environment")
                      return (
                        <EnvironmentFragment
                          key={`fragment-${item.id}`}
                          id={item.id}
                          index={index}
                          setValue={setItemValue}
                        />
                      );
                    if (item.id === "location")
                      return (
                        <LocationFragment
                          key={`fragment-${item.id}`}
                          id={item.id}
                          index={index}
                          setValue={setItemValue}
                        />
                      );
                    if (item.id === "instance")
                      return (
                        <InstanceFragment
                          key={`fragment-${item.id}`}
                          id={item.id}
                          index={index}
                          setValue={setItemValue}
                        />
                      );
                    if (item.id === "random")
                      return (
                        <RandomFragment
                          key={`fragment-${item.id}`}
                          id={item.id}
                          index={index}
                          setValue={setItemValue}
                        />
                      );
                    return <React.Fragment />;
                  })}
              </div>
            )}
          </Droppable>
          <Typography variant="h5" className={classes.title}>
            Selected fragments
          </Typography>
          <Droppable droppableId="selected" direction="horizontal">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                className={classes.droppable}
                // style={getDroppableStyle(snapshot.isDraggingOver)}
                {...provided.droppableProps}
              >
                {selected &&
                  selected.map((item, index) => {
                    if (item.id === "resource-type")
                      return (
                        <ResourceTypeFragment
                          key={`fragment-${item.id}`}
                          id={item.id}
                          index={index}
                          setValue={setItemValue}
                        />
                      );
                    if (item.id === "application")
                      return (
                        <ApplicationFragment
                          key={`fragment-${item.id}`}
                          id={item.id}
                          index={index}
                          setValue={setItemValue}
                        />
                      );
                    if (item.id === "environment")
                      return (
                        <EnvironmentFragment
                          key={`fragment-${item.id}`}
                          id={item.id}
                          index={index}
                          setValue={setItemValue}
                        />
                      );
                    if (item.id === "location")
                      return (
                        <LocationFragment
                          key={`fragment-${item.id}`}
                          id={item.id}
                          index={index}
                          setValue={setItemValue}
                        />
                      );
                    if (item.id === "instance")
                      return (
                        <InstanceFragment
                          key={`fragment-${item.id}`}
                          id={item.id}
                          index={index}
                          setValue={setItemValue}
                        />
                      );
                    if (item.id === "random")
                      return (
                        <RandomFragment
                          key={`fragment-${item.id}`}
                          id={item.id}
                          index={index}
                          setValue={setItemValue}
                        />
                      );
                    return <React.Fragment />;
                  })}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <div className={classes.dataContainer}>
        <DataGrid
          className={classes.dataGrid}
          columns={resourceColumns}
          rows={resources}
          autoHeight={true}
          hideFooter={true}
          components={{
            Toolbar: DataGridToolbar,
          }}
          sortModel={[{ field: "group", sort: "asc" }]}
          disableSelectionOnClick
        />
      </div>
    </div>
  );
};

export default Resources;
