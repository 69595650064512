import {
  AppBar,
  Container,
  Grid,
  Link,
  makeStyles,
  Toolbar,
  Typography,
} from "@material-ui/core";
import NitorLogo from "../images/nitor.svg";
import AzureLogo from "../images/azure.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
  },
  azureLogo: {
    height: "2.50rem",
    verticalAlign: "middle",
    marginRight: theme.spacing(2),
  },
  nitorLogo: {
    height: "2.00rem",
    verticalAlign: "middle",
    marginRight: theme.spacing(2),
  },
  spacer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    width: "1px",
    backgroundColor: theme.palette.text.primary,
    height: "3.50rem",
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <AppBar
      component="footer"
      position="static"
      color="primary"
      className={classes.root}
    >
      <Container>
        <Toolbar>
          <Grid container style={{ alignItems: "center" }}>
            <Grid item>
              <img src={NitorLogo} alt="nitor" className={classes.nitorLogo} />
            </Grid>
            <Grid item>
              <Link
                href="https://nitor.com/en/articles/nitor-core-the-quest-for-something-more-and-better"
                color="textPrimary"
              >
                <Typography variant="caption">
                  Created as a<br />
                  Nitor Core Project
                </Typography>
              </Link>
            </Grid>
            <Grid item className={classes.spacer} />
            <Grid item>
              <img src={AzureLogo} alt="azure" className={classes.azureLogo} />
            </Grid>
            <Grid>
              <Link
                href="https://docs.microsoft.com/en-us/azure/cloud-adoption-framework/"
                color="textPrimary"
              >
                <Typography variant="caption">
                  Tools for Azure
                  <br />
                  Cloud Adaptation Framework
                </Typography>
              </Link>
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Footer;
