import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { InputLabel, ListSubheader, MenuItem, Select } from "@material-ui/core";
import BaseNameFragment from "./BaseNameFragment";
import LocationService from "../services/LocationService";

const LocationFragment = (props) => {
  const { id, index, setValue } = props;
  const [selected, setSelected] = useState("westeurope");

  useEffect(() => {
    setValue(id, selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLocationItems = () => {
    return LocationService.getRecommended().flatMap((location, index) => [
      <ListSubheader key={`location-${index}-sub`}>
        {location.name}
      </ListSubheader>,
      <MenuItem value={location.code.full} key={`location-${index}-full`}>
        {location.code.full}
      </MenuItem>,
      <MenuItem value={location.code.normal} key={`location-${index}-normal`}>
        {location.code.normal}
      </MenuItem>,
      <MenuItem
        value={location.code.normalAlt}
        key={`location-${index}-normalalt`}
      >
        {location.code.normalAlt}
      </MenuItem>,
      <MenuItem value={location.code.short} key={`location-${index}-short`}>
        {location.code.short}
      </MenuItem>,
      <MenuItem
        value={location.code.shortAlt}
        key={`location-${index}-shortalt`}
      >
        {location.code.shortAlt}
      </MenuItem>,
    ]);
  };

  return (
    <BaseNameFragment draggableId={id} index={index} title="Location">
      <InputLabel id={`${id}-label`}></InputLabel>
      <Select
        id={`${id}-value`}
        labelId={`${id}-label`}
        value={selected}
        onChange={(e) => {
          setValue(id, e.target.value);
          setSelected(e.target.value);
        }}
      >
        {getLocationItems()}
      </Select>
    </BaseNameFragment>
  );
};

LocationFragment.propTypes = {
  children: PropTypes.node,
  id: PropTypes.any.isRequired,
  index: PropTypes.any.isRequired,
  setValue: PropTypes.func.isRequired,
};

export default LocationFragment;
