import moment from 'moment';

const tags = [
  {
    "name": "Application name",
    "description": "Added granularity, if the workload is subdivided across multiple applications or services.",
    "key": "ApplicationName",
    "examples": [
      "IssueTrackingSystem"
    ],
    "priority": "Suggested"
  },
  {
    "name": "Workload name",
    "description": "Name of the workload the resource supports.",
    "key": "WorkloadName",
    "examples": [
      "ControlCharts"
    ],
    "priority": "Recommended"
  },
  {
    "name": "Data classification",
    "description": "Sensitivity of data hosted by this resource.",
    "key": "DataClassification",
    "examples": [
      "Non-business",
      "Public",
      "General",
      "Confidential",
      "Highly confidential"
    ],
    "priority": "Recommended"
  },
  {
    "name": "Business criticality",
    "description": "Business impact of the resource or supported workload.",
    "key": "Criticality",
    "examples": [
      "Low",
      "Medium",
      "High",
      "Business unit-critical",
      "Mission-critical"
    ],
    "priority": "Recommended"
  },
  {
    "name": "Business unit",
    "description": "Top-level division of your company that owns the subscription or workload the resource belongs to. In smaller organizations, this may represent a single corporate or shared top-level organizational element.",
    "key": "BusinessUnit",
    "examples": [
      "Finance",
      "Marketing",
      "Product XYZ",
      "Corp",
      "Shared"
    ],
    "priority": "Recommended"
  },
  {
    "name": "Operations commitment",
    "description": "Level of operations support provided for this workload or resource.",
    "key": "OpsCommitment",
    "examples": [
      "Baseline only",
      "Enhanced baseline",
      "Platform operations",
      "Workload operations"
    ],
    "priority": "Recommended"
  },
  {
    "name": "Operations team",
    "description": "Team accountable for day-to-day operations.",
    "key": "OpsTeam",
    "examples": [
      "Central IT",
      "Cloud operations",
      "ControlCharts team",
      "MSP-{Managed Service Provider name}"
    ],
    "priority": "Recommended"
  },
  {
    "name": "Approver name",
    "description": "Person responsible for approving costs related to this resource.",
    "key": "Approver",
    "examples": [
      "ville@nitor.com"
    ],
    "priority": "Suggested"
  },
  {
    "name": "Budget required/approved",
    "description": "Money allocated for this application, service or workload.",
    "key": "BudgetAmount",
    "examples": [
      "20000€"
    ],
    "priority": "Suggested"
  },
  {
    "name": "Cost center",
    "description": "Accounting cost center associated with this resource.",
    "key": "CostCenter",
    "examples": [
      "55332"
    ],
    "priority": "Suggested"
  },
  {
    "name": "Disaster recovery",
    "description": "Business criticality of this application, workload, or service.",
    "key": "DR",
    "examples": [
      "Mission-critical",
      "Critical",
      "Essential"
    ],
    "priority": "Suggested"
  },
  {
    "name": "Start date",
    "description": "Date when this application, workload, or service was first deployed.",
    "key": "StartDate",
    "examples": [
      moment().format('YYYY-MM-DD')
    ],
    "priority": "Suggested"
  },
  {
    "name": "End date",
    "description": "Date when this application, workload, or service is planned to be retired.",
    "key": "EndDate",
    "examples": [
      moment().add(3500, 'days').format('YYYY-MM-DD')
    ],
    "priority": "Suggested"
  },
  {
    "name": "Environment",
    "description": "Deployment environment of this application, workload, or service.",
    "key": "Env",
    "examples": [
      "Prod",
      "Dev",
      "QA",
      "Stage",
      "Test"
    ],
    "priority": "Suggested"
  },
  {
    "name": "Owner name",
    "description": "Owner of the application, workload, or service.",
    "key": "Owner",
    "examples": [
      "olli@nitor.com"
    ],
    "priority": "Suggested"
  },
  {
    "name": "Requester name",
    "description": "User that requested the creation of this application.",
    "key": "Requester",
    "examples": [
      "matti@nitor.com"
    ],
    "priority": "Suggested"
  },
  {
    "name": "Service class",
    "description": "Service Level Agreement level of this application, workload, or service.",
    "key": "ServiceClass",
    "examples": [
      "Dev",
      "Bronze",
      "Silver",
      "Gold"
    ],
    "priority": "Suggested"
  },
];

export default tags;