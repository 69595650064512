import React from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Button,
  Grid,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core";
import LocationsImage from "../../images/locations.png";
import ResourcesImage from "../../images/resources.png";
import TaggingImage from "../../images/tagging.png";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  card: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
    marginTop: theme.spacing(4),
  },
  cardContent: {
    width: "55%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  cardMedia: {
    width: "45%",
    height: 350,
    backgroundPositionY: "top",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: 250,
    },
  },
  cardButton: {
    marginTop: theme.spacing(4),
  },
}));

const AboutCard = (props) => {
  const classes = useStyles();

  return (
    <Card className={classes.card} variant="elevation" elevation={10}>
      <CardMedia
        className={classes.cardMedia}
        image={props.image}
        title={props.imageTitle}
      />
      <CardContent className={classes.cardContent}>
        {props.children}
      </CardContent>
    </Card>
  );
};

const About = (props) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root} spacing={4}>
      <Grid item xs={12} md={9}>
        <Typography variant="h2" gutterBottom>
          What is it about?
        </Typography>
        <Typography align="justify" gutterBottom>
          Naming and labeling things is hard, and Azure resources are no
          exception. Establishing a well defined naming and tagging strategy for
          your Azure resources early on significantly improves your resource
          management and governance. Following Azure Cloud Adoptation
          Framework's best practises for naming and tagging we have developed
          simple tools to help with this task. Please note that at the moment
          the tools work best with a desktop browser.
        </Typography>
        <Typography gutterBottom>
          More information on naming and tagging in Microsoft Docs:
          <ul>
            <li>
              <Link href="https://docs.microsoft.com/en-us/azure/cloud-adoption-framework/ready/azure-best-practices/naming-and-tagging">
                Develop your naming and tagging strategy for Azure resources
              </Link>
            </li>
            <li>
              <Link href="https://docs.microsoft.com/en-us/azure/cloud-adoption-framework/ready/azure-best-practices/resource-abbreviations">
                Recommended abbreviations for Azure resource types
              </Link>
            </li>
            <li>
              <Link href="https://docs.microsoft.com/en-us/azure/cloud-adoption-framework/ready/azure-best-practices/resource-tagging">
                Define your tagging strategy
              </Link>
            </li>
          </ul>
        </Typography>
        <Typography>
          Like it, missing something you want or have suggestion? Let us know
          about it:&nbsp;
          <Link href="mailto:aztools@nitor.com">aztools@nitor.com</Link>
        </Typography>
        <AboutCard image={LocationsImage} imageTitle="Locations">
          <Typography variant="h5" component="h2" gutterBottom>
            Locations
          </Typography>
          <Typography gutterBottom>
            Azure provides many locations (or regions) to host your resources.
            While not mandatory, it is a good idea to use a location code as
            part of the naming scheme for your resourses.
          </Typography>
          <Typography>
            The data is presented in a simple data table that can be searched,
            filtered, ordered and exported as a CSV file.
          </Typography>
          <Button
            color="primary"
            variant="contained"
            href="/locations"
            className={classes.cardButton}
          >
            View locations
          </Button>
        </AboutCard>
        <AboutCard image={ResourcesImage} imageTitle="Resources">
          <Typography variant="h5" component="h2" gutterBottom>
            Resources
          </Typography>
          <Typography gutterBottom>
            Build your naming scheme by configuring, dragging and dropping
            pre-made naming fragments. Check by resource type wether the name is
            compliant with the Azure resource naming rules.
          </Typography>
          <Typography>
            The data is presented in a simple data table that can be searched,
            filtered, ordered and exported as a CSV file.
          </Typography>
          <Button
            color="primary"
            variant="contained"
            href="/resources"
            className={classes.cardButton}
          >
            View resources
          </Button>
        </AboutCard>
        <AboutCard image={TaggingImage} imageTitle="Tagging">
          <Typography variant="h5" component="h2" gutterBottom>
            Tagging
          </Typography>
          <Typography gutterBottom>
            Plan you tagging policies by going through the recommended and
            suggested tags. Select example values per tag or provide your own.
          </Typography>
          <Typography>
            The data is presented in a simple data table that can be searched,
            filtered, ordered and exported as a CSV file.
          </Typography>
          <Button
            color="primary"
            variant="contained"
            href="/tagging"
            className={classes.cardButton}
          >
            View tagging
          </Button>
        </AboutCard>
      </Grid>
    </Grid>
  );
};

export default About;
