import React, { useEffect, useState } from "react";
import { FormControlLabel, makeStyles, Switch } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import LocationService from "../../services/LocationService";
import MapChart from "../../components/MapChart";
import DataGridToolbar from "../../components/DataGridToolbar";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  dataContainer: {
    width: "100%",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  dataGrid: {
    border: 0,
    "& .MuiDataGrid-main": {
      marginTop: theme.spacing(2),
    },
  },
  highlight: {
    backgroundColor: theme.palette.grey[800],
  },
  highlightLeft: {
    borderLeft: "2px solid",
    borderLeftColor: theme.palette.grey[700],
  },
  highlightRight: {
    borderRight: "2px solid",
    borderRightColor: theme.palette.grey[700],
  },
  mapContainer: {
    position: "relative",
  },
  mapOverlay: {
    width: "25%",
    position: "absolute",
    bottom: theme.spacing(4),
    left: 0,
  },
  switchLabel: theme.typography.body2,
}));

const Locations = (props) => {
  const classes = useStyles();
  const [locations, setLocations] = useState([]);
  const [showRecommended, setShowRecommended] = useState(true);

  const columns = [
    {
      field: "group",
      headerName: "Group",
      width: 150,
    },
    {
      field: "name",
      headerName: "Name",
      width: 200,
    },
    {
      field: "location",
      headerName: "Location",
      width: 200,
      cellClassName: clsx(classes.highlight, classes.highlightLeft),
    },
    {
      field: "code.normal",
      headerName: "Code",
      width: 110,
      cellClassName: classes.highlight,
      valueGetter: (value) => value.row.code.normal,
    },
    {
      field: "code.normalAlt",
      headerName: "Code Alt",
      width: 110,
      cellClassName: classes.highlight,
      valueGetter: (value) => value.row.code.normalAlt,
    },
    {
      field: "code.short",
      headerName: "Short",
      width: 110,
      cellClassName: classes.highlight,
      valueGetter: (value) => value.row.code.short,
    },
    {
      field: "code.shortAlt",
      headerName: "Short Alt",
      width: 110,
      cellClassName: clsx(classes.highlight, classes.highlightRight),
      valueGetter: (value) => value.row.code.shortAlt,
    },
  ];

  useEffect(() => {
    setLocations(
      showRecommended
        ? LocationService.getRecommended()
        : LocationService.getAll()
    );
  }, [showRecommended]);

  const handleShowRecommendedChange = (event) => {
    setShowRecommended(event.target.checked);
  };

  return (
    <React.Fragment>
      <div className={classes.mapContainer}>
        <MapChart locations={locations} />
        <div className={classes.mapOverlay}>
          <FormControlLabel
            classes={{
              label: classes.switchLabel,
            }}
            control={
              <Switch
                checked={showRecommended}
                onChange={handleShowRecommendedChange}
                name="showRecommended"
                color="primary"
              />
            }
            label="Show only recommended locations"
          />
        </div>
      </div>
      <div className={classes.dataContainer}>
        <DataGrid
          className={classes.dataGrid}
          columns={columns}
          rows={locations}
          autoHeight={true}
          hideFooter={true}
          components={{
            Toolbar: DataGridToolbar,
          }}
          sortModel={[{ field: "group", sort: "asc" }]}
          disableSelectionOnClick
        />
      </div>
    </React.Fragment>
  );
};

export default Locations;
