import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { TextField } from "@material-ui/core";
import BaseNameFragment from "./BaseNameFragment";

const InstanceFragment = (props) => {
  const { id, index, setValue } = props;
  const [length, setLength] = useState(2);

  useEffect(() => {
    setValue(id, pad(length));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pad = (width) => (width <= 1 ? "1" : new Array(width).join("0") + "1");

  const handleChange = (e) => {
    if (e.target.value > 0 && e.target.value <= 6) {
      setLength(e.target.value);
      setValue(id, pad(parseInt(e.target.value)));
    }
  };

  return (
    <BaseNameFragment draggableId={id} index={index} title="Instance">
      <TextField
        id={`${id}-value`}
        type="number"
        label="Digits"
        variant="outlined"
        size="small"
        aria-posinset={index}
        onChange={handleChange}
        value={length}
      />
    </BaseNameFragment>
  );
};

InstanceFragment.propTypes = {
  children: PropTypes.node,
  id: PropTypes.any.isRequired,
  index: PropTypes.any.isRequired,
  setValue: PropTypes.func.isRequired,
};

export default InstanceFragment;
