import { useEffect } from "react";
import PropTypes from "prop-types";
import { TextField } from "@material-ui/core";
import BaseNameFragment from "./BaseNameFragment";

const ApplicationFragment = (props) => {
  const { id, index, setValue } = props;
  const defaultValue = "myapp";

  useEffect(() => {
    setValue(id, defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BaseNameFragment draggableId={id} index={index} title="Application">
      <TextField
        id={`${id}-value`}
        label="Value"
        variant="outlined"
        size="small"
        aria-posinset={index}
        onChange={(e) => setValue(id, e.target.value)}
        defaultValue={defaultValue}
      />
    </BaseNameFragment>
  );
};

ApplicationFragment.propTypes = {
  children: PropTypes.node,
  id: PropTypes.any.isRequired,
  index: PropTypes.any.isRequired,
  setValue: PropTypes.func.isRequired,
};

export default ApplicationFragment;
