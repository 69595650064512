import PropTypes from "prop-types";
import BaseNameFragment from "./BaseNameFragment";

const ResourceTypeFragment = (props) => {
  const { id, index } = props;

  return (
    <BaseNameFragment draggableId={id} index={index} title="Resource type" />
  );
};

ResourceTypeFragment.propTypes = {
  children: PropTypes.node,
  id: PropTypes.any.isRequired,
  index: PropTypes.any.isRequired,
};

export default ResourceTypeFragment;
