import React from "react";
import { makeStyles, TextField } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { Autocomplete } from "@material-ui/lab";
import TagCloud from "react-tag-cloud";
import randomColor from "randomcolor";
import TagService from "../../services/TagService";
import DataGridToolbar from "../../components/DataGridToolbar";
import ReactTooltip from "react-tooltip";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  dataContainer: {
    width: "100%",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  dataGrid: {
    border: 0,
    "& .MuiDataGrid-main": {
      marginTop: theme.spacing(2),
    },
  },
  highlight: {
    backgroundColor: theme.palette.grey[800],
  },
  highlightLeft: {
    borderLeft: "2px solid",
    borderLeftColor: theme.palette.grey[700],
  },
  highlightRight: {
    borderRight: "2px solid",
    borderRightColor: theme.palette.grey[700],
  },
  valueInput: {
    marginTop: theme.spacing(0.5),
  },
  tagCloud: {
    width: "100%",
    height: 375,
    marginTop: theme.spacing(4),
  },
  tagTooltip: {
    maxWidth: "25%",
    zIndex: "1500 !important",
  },
  tagItemWrapper: {
    cursor: "pointer",
  },
}));

const tagStyles = {
  default: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 32,
    color: () =>
      randomColor({
        hue: "blue",
        luminosity: "light",
      }),
    padding: 10,
  },
  Recommended: {
    fontSize: 42,
  },
  Suggested: {
    fontSize: 32,
  },
};

const Tagging = (props) => {
  const classes = useStyles();

  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 200,
    },
    {
      field: "priority",
      headerName: "Priority",
      width: 150,
    },
    {
      field: "description",
      headerName: "Description",
      width: 350,
    },
    {
      field: "key",
      headerName: "Key",
      width: 150,
      cellClassName: clsx(classes.highlight, classes.highlightLeft),
    },
    {
      field: "value",
      headerName: "Value",
      width: 250,
      cellClassName: clsx(classes.highlight, classes.highlightRight),
      renderCell: (params) => (
        <div style={{ width: "100%" }}>
          <Autocomplete
            id={`${params.row.key}-example}`}
            freeSolo
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            options={params.row.examples || []}
            renderInput={(p) => (
              <TextField
                {...p}
                placeholder="Select or write.."
                style={{ marginTop: 8 }}
                variant="outlined"
              />
            )}
          />
        </div>
      ),
    },
  ];

  return (
    <React.Fragment>
      <TagCloud className={classes.tagCloud} style={tagStyles.default}>
        {TagService.getAll().map((tag) => (
          <div
            key={`${tag.key}-tag-cloud`}
            style={tagStyles[tag.priority] || {}}
            className={classes.tagItemWrapper}
            onMouseEnter={() => ReactTooltip.rebuild()}
            onMouseLeave={() => ReactTooltip.rebuild()}
            data-tip={
              `<b>Name:</b> ${tag.name}<br/>` +
              `<b>Priority:</b> ${tag.priority}<br/>` +
              `<b>Description:</b> ${tag.description}<br/>` +
              `<b>Examples:</b> ${tag.examples.join(", ")}</br>`
            }
          >
            {tag.key}
          </div>
        ))}
      </TagCloud>
      <ReactTooltip type="info" html={true} className={classes.tagTooltip} />
      <div className={classes.dataContainer}>
        <DataGrid
          className={classes.dataGrid}
          columns={columns}
          rows={TagService.getAll()}
          autoHeight={true}
          hideFooter={true}
          components={{
            Toolbar: DataGridToolbar,
          }}
          sortModel={[{ field: "priority", sort: "asc" }]}
          disableSelectionOnClick
        />
      </div>
    </React.Fragment>
  );
};

export default Tagging;
