import React from "react";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./services/AppInsights";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Azure from "./pages/azure/Azure";

function App() {
  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <Router>
        <Switch>
          <Route path="/">
            <Azure />
          </Route>
        </Switch>
      </Router>
    </AppInsightsContext.Provider>
  );
}

export default App;
