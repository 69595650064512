const resources = [
  {
    "name": "Analytics Services server",
    "group": "Analytics and IoT",
    "length": {
      "min": 3,
      "max": 63
    },
    "regex": "^(?=.{3,63}$)[a-z][a-z0-9]+$",
    "scope": "Resource group",
    "slug": "as",
    "dashes": false
  },
  {
    "name": "API management service instance",
    "group": "General",
    "length": {
      "min": 1,
      "max": 50
    },
    "regex": "^(?=.{1,50}$)[a-z][a-zA-Z0-9]+$",
    "scope": "Global",
    "slug": "apim",
    "dashes": false
  },
  {
    "name": "App Configuration store",
    "group": "Developer tools",
    "length": {
      "min": 5,
      "max": 50
    },
    "regex": "^(?=.{5,50}$)[a-zA-Z0-9_-]+$",
    "scope": "Resource group",
    "slug": "appcs",
    "dashes": true
  },
  {
    "name": "Application Gateway",
    "group": "Networking",
    "length": {
      "min": 1,
      "max": 80
    },
    "regex": "^(?=.{1,80}$)[a-zA-Z0-9][a-zA-Z0-9-._]+[a-zA-Z0-9_]$",
    "scope": "Resource group",
    "slug": "agw",
    "dashes": true
  },
  {
    "name": "Application Insigths",
    "group": "Management and governance",
    "length": {
      "min": 10,
      "max": 260
    },
    "regex": "^[^%\\&?/]+$",
    "scope": "Resource group",
    "slug": "appi",
    "dashes": true
  },
  {
    "name": "Application security group (ASG)",
    "group": "Networking",
    "length": {
      "min": 1,
      "max": 80
    },
    "regex": "^(?=.{1,80}$)[a-zA-Z0-9][a-zA-Z0-9-._]+[a-zA-Z0-9_]$",
    "scope": "Resource group",
    "slug": "asg",
    "dashes": true
  },
  {
    "name": "Automation account",
    "group": "Management and governance",
    "length": {
      "min": 6,
      "max": 50
    },
    "regex": "^(?=.{6,50}$)[a-zA-Z][a-zA-Z0-9-]+[a-zA-Z0-9]$",
    "scope": "Resource group",
    "slug": "aa",
    "dashes": true
  },
  {
    "name": "Availability set",
    "group": "Compute and Web",
    "length": {
      "min": 1,
      "max": 80
    },
    "regex": "^(?=.{1,80}$)[a-zA-Z0-9][a-zA-Z0-9-_.]+[a-zA-Z0-9_]$",
    "scope": "Resource group",
    "slug": "avail",
    "dashes": true
  },
  {
    "name": "Batch account",
    "group": "Compute and Web",
    "length": {
      "min": 3,
      "max": 24
    },
    "regex": "^(?=.{3,24}$)[a-z0-9]+$",
    "scope": "Region",
    "slug": "ba",
    "dashes": false
  },
  {
    "name": "Bot web app",
    "group": "AI and Machine Learning",
    "length": {
      "min": 2,
      "max": 64
    },
    "regex": "^(?=.{2,64}$)[a-zA-Z0-9][a-zA-Z0-9-_.]+$",
    "scope": "Global",
    "slug": "bot",
    "dashes": true
  },
  {
    "name": "CDN endpoint",
    "group": "Networking",
    "length": {
      "min": 1,
      "max": 50
    },
    "regex": "^(?=.{1,50}$)[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]$",
    "scope": "Global",
    "slug": "cdne",
    "dashes": true
  },
  {
    "name": "CDN profile",
    "group": "Networking",
    "length": {
      "min": 1,
      "max": 260
    },
    "regex": "^(?=.{1,260}$)[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]$",
    "scope": "Resource group",
    "slug": "cdnp",
    "dashes": true
  },
  {
    "name": "Azure Cognitive Services",
    "group": "AI and Machine Learning",
    "length": {
      "min": 2,
      "max": 64
    },
    "regex": "^(?=.{1,260}$)[a-zA-Z0-9][a-zA-Z0-9-]+$",
    "scope": "Resource group",
    "slug": "cog",
    "dashes": true
  },
  {
    "name": "Container group",
    "group": "Compute and Web",
    "length": {
      "min": 1,
      "max": 63
    },
    "regex": "^(?=.{1,63}$)(?!.*--)[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]$",
    "scope": "Resource group",
    "slug": "cg",
    "dashes": true
  },
  {
    "name": "Azure Container registry",
    "group": "Storage",
    "length": {
      "min": 1,
      "max": 63
    },
    "regex": "^(?=.{1,63}$)[a-zA-Z0-9]+$",
    "scope": "Resource group",
    "slug": "acr",
    "dashes": false
  },
  {
    "name": "Container registry webhook",
    "group": "Compute and Web",
    "length": {
      "min": 1,
      "max": 50
    },
    "regex": "^(?=.{5,50}$)[a-zA-Z0-9]+$",
    "scope": "Resource group",
    "slug": "crwh",
    "dashes": false
  },
  {
    "name": "Azure Cosmos DB account",
    "group": "Databases",
    "length": {
      "min": 1,
      "max": 63
    },
    "regex": "^(?=.{1,63}$)[a-z0-9][a-z0-9-_.]+[a-z0-9]$",
    "scope": "Resource group",
    "slug": "cosmos",
    "dashes": true
  },
  {
    "name": "Azure Data Factory",
    "group": "Analytics and IoT",
    "length": {
      "min": 3,
      "max": 63
    },
    "regex": "^(?=.{3,63}$)[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]$",
    "scope": "Global",
    "slug": "adf",
    "dashes": true
  },
  {
    "name": "Data Lake Analytics account",
    "group": "Analytics and IoT",
    "length": {
      "min": 3,
      "max": 24
    },
    "regex": "^(?=.{3,24}$)[a-z0-9]+$",
    "scope": "Global",
    "slug": "dla",
    "dashes": false
  },
  {
    "name": "Database Migration Service instance",
    "group": "Migration",
    "length": {
      "min": 2,
      "max": 62
    },
    "regex": "^(?=.{2,62}$)[a-zA-Z0-9][a-zA-Z0-9-_.]+$",
    "scope": "Resource group",
    "slug": "dms",
    "dashes": true
  },
  {
    "name": "Azure Databricks workspace",
    "group": "Analytics and IoT",
    "length": {
      "min": 3,
      "max": 30
    },
    "regex": "^(?=.{3,30}$)[a-zA-Z0-9-_]+$",
    "scope": "Resource group",
    "slug": "dbw",
    "dashes": true
  },
  {
    "name": "Dev test lab",
    "group": "Developer tools",
    "length": {
      "min": 1,
      "max": 50
    },
    "regex": "^(?=.{1,50}$)[a-zA-Z0-9-_]+$",
    "scope": "Resource group",
    "slug": "lab",
    "dashes": true
  },
  {
    "name": "Event Grid domain",
    "group": "Analytics and IoT",
    "length": {
      "min": 3,
      "max": 50
    },
    "regex": "^(?=.{3,50}$)[a-zA-Z0-9-]+$",
    "scope": "Resource group",
    "slug": "evgd",
    "dashes": true
  },
  {
    "name": "Event Grid subscription",
    "group": "Analytics and IoT",
    "length": {
      "min": 3,
      "max": 64
    },
    "regex": "^(?=.{3,64}$)[a-zA-Z0-9-]+$",
    "scope": "Resource group",
    "slug": "evgs",
    "dashes": true
  },
  {
    "name": "Event Grid topic",
    "group": "Analytics and IoT",
    "length": {
      "min": 3,
      "max": 50
    },
    "regex": "^(?=.{3,50}$)[a-zA-Z0-9-]+$",
    "scope": "Resource group",
    "slug": "evgt",
    "dashes": true
  },
  {
    "name": "Event Hubs namespace",
    "group": "Analytics and IoT",
    "length": {
      "min": 1,
      "max": 50
    },
    "regex": "^(?=.{1,50}$)[a-zA-Z][a-zA-Z0-9-]+[a-zA-Z0-9]$",
    "scope": "Global",
    "slug": "evh",
    "dashes": true
  },
  {
    "name": "ExpressRoute circuit",
    "group": "Networking",
    "length": {
      "min": 1,
      "max": 80
    },
    "regex": "^(?=.{1,80}$)[a-zA-Z0-9][a-zA-Z0-9-._]+[a-zA-Z0-9_]$",
    "scope": "Resource group",
    "slug": "erc",
    "dashes": true
  },
  {
    "name": "ExpressRoute gateway",
    "group": "Networking",
    "length": {
      "min": 1,
      "max": 80
    },
    "regex": "^(?=.{1,80}$)[a-zA-Z0-9][a-zA-Z0-9-._]+[a-zA-Z0-9_]$",
    "scope": "Resource group",
    "slug": "ergw",
    "dashes": true
  },
  {
    "name": "Firewall",
    "group": "Networking",
    "length": {
      "min": 1,
      "max": 80
    },
    "regex": "^(?=.{1,80}$)[a-zA-Z0-9][a-zA-Z0-9-._]+[a-zA-Z0-9_]$",
    "scope": "Resource group",
    "slug": "fw",
    "dashes": true
  },
  {
    "name": "Front door",
    "group": "Networking",
    "length": {
      "min": 5,
      "max": 64
    },
    "regex": "^(?=.{5,64}$)[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]$",
    "scope": "Global",
    "slug": "fd",
    "dashes": true
  },
  {
    "name": "Front door firewall policy",
    "group": "Networking",
    "length": {
      "min": 1,
      "max": 80
    },
    "regex": "^(?=.{1,80}$)[a-zA-Z0-9][a-zA-Z0-9-._]+[a-zA-Z0-9_]$",
    "scope": "Global",
    "slug": "fdfw",
    "dashes": true
  },
  {
    "name": "Function app",
    "group": "Compute and Web",
    "length": {
      "min": 2,
      "max": 60
    },
    "regex": "^(?=.{2,60}$)[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]$",
    "scope": "Global",
    "slug": "func",
    "dashes": true
  },
  {
    "name": "HDInsight - Hadoop cluster",
    "group": "Analytics and IoT",
    "length": {
      "min": 3,
      "max": 59
    },
    "regex": "^(?=.{3,59}$)[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]$",
    "scope": "Global",
    "slug": "hadoop",
    "dashes": true
  },
  {
    "name": "HDInsight - HBase cluster",
    "group": "Analytics and IoT",
    "length": {
      "min": 3,
      "max": 59
    },
    "regex": "^(?=.{3,59}$)[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]$",
    "scope": "Global",
    "slug": "hbase",
    "dashes": true
  },
  {
    "name": "HDInsight - Kafka cluster",
    "group": "Analytics and IoT",
    "length": {
      "min": 3,
      "max": 59
    },
    "regex": "^(?=.{3,59}$)[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]$",
    "scope": "Global",
    "slug": "kafka",
    "dashes": true
  },
  {
    "name": "HDInsight - ML Services cluster",
    "group": "Analytics and IoT",
    "length": {
      "min": 3,
      "max": 59
    },
    "regex": "^(?=.{3,59}$)[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]$",
    "scope": "Global",
    "slug": "mls",
    "dashes": true
  },
  {
    "name": "HDInsight - Spark cluster",
    "group": "Analytics and IoT",
    "length": {
      "min": 3,
      "max": 59
    },
    "regex": "^(?=.{3,59}$)[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]$",
    "scope": "Global",
    "slug": "spark",
    "dashes": true
  },
  {
    "name": "HDInsight - Storm cluster",
    "group": "Analytics and IoT",
    "length": {
      "min": 3,
      "max": 59
    },
    "regex": "^(?=.{3,59}$)[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]$",
    "scope": "Global",
    "slug": "storm",
    "dashes": true
  },
  {
    "name": "Image",
    "group": "Compute and Web",
    "length": {
      "min": 1,
      "max": 80
    },
    "regex": "^(?=.{1,80}$)[a-zA-Z0-9][a-zA-Z0-9-_.]+[a-zA-Z0-9_]$",
    "scope": "Resource group",
    "slug": "img",
    "dashes": true
  },
  {
    "name": "IoT hub application",
    "group": "Analytics and IoT",
    "length": {
      "min": 2,
      "max": 63
    },
    "regex": "^(?=.{2,63}$)[a-z0-9][a-z0-9-]+[a-z0-9]$",
    "scope": "Global",
    "slug": "iotapp",
    "dashes": true
  },
  {
    "name": "IoT hub",
    "group": "Analytics and IoT",
    "length": {
      "min": 3,
      "max": 50
    },
    "regex": "^(?=.{3,50}$)[a-zA-Z0-9][a-zA-Z0-9-]+[a-z0-9]$",
    "scope": "Global",
    "slug": "iot",
    "dashes": true
  },
  {
    "name": "IoT hub dps",
    "group": "Analytics and IoT",
    "length": {
      "min": 3,
      "max": 64
    },
    "regex": "^(?=.{3,64}$)[a-zA-Z0-9-]+[a-zA-Z0-9]$",
    "scope": "Resource group",
    "slug": "dps",
    "dashes": true
  },
  {
    "name": "Key Vault",
    "group": "Management and governance",
    "length": {
      "min": 3,
      "max": 24
    },
    "regex": "^(?=.{3,24}$)(?!.*--)[a-zA-Z][a-zA-Z0-9-]+[a-zA-Z0-9]$",
    "scope": "Global",
    "slug": "kv",
    "dashes": true
  },
  {
    "name": "AKS cluster",
    "group": "Compute and Web",
    "length": {
      "min": 1,
      "max": 63
    },
    "regex": "^(?=.{1,63}$)[a-zA-Z0-9][a-zA-Z0-9-_.]+[a-zA-Z0-9]$",
    "scope": "Resource group",
    "slug": "aks",
    "dashes": true
  },
  {
    "name": "Load balancer (internal)",
    "group": "Networking",
    "length": {
      "min": 1,
      "max": 80
    },
    "regex": "^(?=.{1,80}$)[a-zA-Z0-9][a-zA-Z0-9-._]+[a-zA-Z0-9_]$",
    "scope": "Resource group",
    "slug": "lbi",
    "dashes": true
  },
  {
    "name": "Load balancer (external)",
    "group": "Networking",
    "length": {
      "min": 1,
      "max": 80
    },
    "regex": "^(?=.{1,80}$)[a-zA-Z0-9][a-zA-Z0-9-._]+[a-zA-Z0-9_]$",
    "scope": "Resource group",
    "slug": "lbe",
    "dashes": true
  },
  {
    "name": "Linux virtual machine",
    "group": "Compute and Web",
    "length": {
      "min": 1,
      "max": 64
    },
    "regex": "^(?=.{1,64}$)[^\\/\\\"\\[\\]:|<>+=;,?*@&_][^\\/\\\"\\[\\]:|<>+=;,?*@&]+[^\\/\\\"\\[\\]:|<>+=;,?*@&.-]$",
    "scope": "Resource group",
    "slug": "vm",
    "dashes": true
  },
  {
    "name": "Linux virtual machine scale set",
    "group": "Compute and Web",
    "length": {
      "min": 1,
      "max": 64
    },
    "regex": "^(?=.{1,64}$)[^\\/\\\"\\[\\]:|<>+=;,?*@&_][^\\/\\\"\\[\\]:|<>+=;,?*@&]+[^\\/\\\"\\[\\]:|<>+=;,?*@&.-]$",
    "scope": "Resource group",
    "slug": "vmss",
    "dashes": true
  },
  {
    "name": "Local network gateway",
    "group": "Networking",
    "length": {
      "min": 1,
      "max": 80
    },
    "regex": "^(?=.{1,80}$)[a-zA-Z0-9][a-zA-Z0-9-._]+[a-zA-Z0-9_]$",
    "scope": "Resource group",
    "slug": "lgw",
    "dashes": true
  },
  {
    "name": "Azure Machine Learning workspace",
    "group": "AI and Machine Learning",
    "length": {
      "min": 1,
      "max": 260
    },
    "regex": "^(?=.{1,260}$)[^<>*%:.?\\+\\/]+[^<>*%:.?\\+\\/ ]$",
    "scope": "Resource group",
    "slug": "mlw",
    "dashes": true
  },
  {
    "name": "Managed disk (OS)",
    "group": "Compute and Web",
    "length": {
      "min": 1,
      "max": 80
    },
    "regex": "^(?=.{1,80}$)[a-zA-Z0-9_-]+$",
    "scope": "Resource group",
    "slug": "osdisk",
    "dashes": true
  },
  {
    "name": "Managed disk (data)",
    "group": "Compute and Web",
    "length": {
      "min": 1,
      "max": 80
    },
    "regex": "^(?=.{1,80}$)[a-zA-Z0-9_-]+$",
    "scope": "Resource group",
    "slug": "disk",
    "dashes": true
  },
  {
    "name": "Azure SQL database",
    "group": "Databases",
    "length": {
      "min": 1,
      "max": 63
    },
    "regex": "^(?=.{1,63}$)[a-z0-9][a-z0-9-]+[a-z0-9]$",
    "scope": "Global",
    "slug": "sqldb",
    "dashes": true
  },
  {
    "name": "MySQL database",
    "group": "Databases",
    "length": {
      "min": 3,
      "max": 63
    },
    "regex": "^(?=.{3,63}$)[a-z0-9][a-zA-Z0-9-]+[a-z0-9]$",
    "scope": "Global",
    "slug": "mysql",
    "dashes": true
  },
  {
    "name": "Network interface (NIC)",
    "group": "Networking",
    "length": {
      "min": 1,
      "max": 80
    },
    "regex": "^(?=.{1,80}$)[a-zA-Z0-9][a-zA-Z0-9-._]+[a-zA-Z0-9_]$",
    "scope": "Resource group",
    "slug": "nic",
    "dashes": true
  },
  {
    "name": "Network security group (NSG)",
    "group": "Networking",
    "length": {
      "min": 1,
      "max": 80
    },
    "regex": "^(?=.{1,80}$)[a-zA-Z0-9][a-zA-Z0-9-._]+[a-zA-Z0-9_]$",
    "scope": "Resource group",
    "slug": "nsg",
    "dashes": true
  },
  {
    "name": "Notification Hubs namespace",
    "group": "Compute and Web",
    "length": {
      "min": 6,
      "max": 50
    },
    "regex": "^(?=.{6,50}$)[a-zA-Z][a-zA-Z0-9-]+[a-zA-Z0-9]$",
    "scope": "Global",
    "slug": "ntfns",
    "dashes": true
  },
  {
    "name": "VPN connection",
    "group": "Networking",
    "length": {
      "min": 1,
      "max": 80
    },
    "regex": "^(?=.{1,80}$)[a-zA-Z0-9][a-zA-Z0-9-._]+[a-zA-Z0-9_]$",
    "scope": "Resource group",
    "slug": "cn",
    "dashes": true
  },
  {
    "name": "PostgreSQL database",
    "group": "Databases",
    "length": {
      "min": 3,
      "max": 63
    },
    "regex": "^(?=.{3,63}$)[a-z0-9][a-zA-Z0-9-]+[a-z0-9]$",
    "scope": "Global",
    "slug": "psql",
    "dashes": true
  },
  {
    "name": "Power BI Embedded",
    "group": "Analytics and IoT",
    "length": {
      "min": 3,
      "max": 63
    },
    "regex": "^(?=.{3,63}$)(?!.*--)[a-zA-Z0-9][a-zA-Z0-9-]+$",
    "scope": "Region",
    "slug": "pbi",
    "dashes": true
  },
  {
    "name": "Azure Cache for Redis instance",
    "group": "Databases",
    "length": {
      "min": 1,
      "max": 63
    },
    "regex": "^(?=.{1,50}$)(?!.*--)[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]$",
    "scope": "Global",
    "slug": "redis",
    "dashes": true
  },
  {
    "name": "Resource group",
    "group": "General",
    "length": {
      "min": 1,
      "max": 90
    },
    "regex": "^(?=.{1,90}$)[a-zA-Z0-9-._\\(\\)]+[a-zA-Z0-9-_\\(\\)]$",
    "scope": "Subscription",
    "slug": "rg",
    "dashes": true
  },
  {
    "name": "Route table",
    "group": "Networking",
    "length": {
      "min": 1,
      "max": 80
    },
    "regex": "^(?=.{1,80}$)[a-zA-Z0-9][a-zA-Z0-9-._]+[a-zA-Z0-9_]$",
    "scope": "Resource group",
    "slug": "route",
    "dashes": true
  },
  {
    "name": "Service Fabric cluster",
    "group": "Compute and Web",
    "length": {
      "min": 4,
      "max": 23
    },
    "regex": "^(?=.{4,23}$)[a-z][a-z0-9-]+[a-z0-9]$",
    "scope": "Region",
    "slug": "sf",
    "dashes": true
  },
  {
    "name": "Service Bus",
    "group": "Integration",
    "length": {
      "min": 6,
      "max": 50
    },
    "regex": "^(?=.{6,50}$)[a-zA-Z][a-zA-Z0-9-]+[a-zA-Z0-9]$",
    "scope": "Global",
    "slug": "sb",
    "dashes": true
  },
  {
    "name": "Shared image gallery",
    "group": "Compute and Web",
    "length": {
      "min": 1,
      "max": 80
    },
    "regex": "^(?=.{1,80}$)[a-zA-Z0-9][a-zA-Z0-9.]+[a-zA-Z0-9]$",
    "scope": "Resource group",
    "slug": "sig",
    "dashes": false
  },
  {
    "name": "SignalR service",
    "group": "Integration",
    "length": {
      "min": 3,
      "max": 63
    },
    "regex": "^(?=.{3,63}$)[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]$",
    "scope": "Global",
    "slug": "sgnlr",
    "dashes": true
  },
  {
    "name": "Azure SQL Database server",
    "group": "Databases",
    "length": {
      "min": 1,
      "max": 63
    },
    "regex": "^(?=.{1,63}$)[a-z0-9][a-z0-9-]+[a-z0-9]$",
    "scope": "Global",
    "slug": "sql",
    "dashes": true
  },
  {
    "name": "Storage account",
    "group": "Storage",
    "length": {
      "min": 3,
      "max": 24
    },
    "regex": "^(?=.{3,24}$)[a-z0-9]+$",
    "scope": "Global",
    "slug": "st",
    "dashes": false
  },
  {
    "name": "Azure Stream Analytics",
    "group": "Analytics and IoT",
    "length": {
      "min": 3,
      "max": 63
    },
    "regex": "^(?=.{1,63}$)[a-zA-Z0-9-_]+$",
    "scope": "Resource group",
    "slug": "asa",
    "dashes": true
  },
  {
    "name": "Traffic Manager profile",
    "group": "Networking",
    "length": {
      "min": 1,
      "max": 63
    },
    "regex": "^(?=.{1,63}$)[a-zA-Z0-9][a-zA-Z0-9-.]+[a-zA-Z0-9_]$",
    "scope": "Global",
    "slug": "traf",
    "dashes": true
  },
  {
    "name": "Virtual machine",
    "group": "Compute and Web",
    "length": {
      "min": 1,
      "max": 15
    },
    "regex": "^(?=.{1,15}$)[^\\/\\\"\\[\\]:|<>+=;,?*@&_][^\\/\\\"\\[\\]:|<>+=;,?*@&]+[^\\/\\\"\\[\\]:|<>+=;,?*@&.-]$",
    "scope": "Resource group",
    "slug": "vm",
    "dashes": true
  },
  {
    "name": "Virtual machine scale set",
    "group": "Compute and Web",
    "length": {
      "min": 1,
      "max": 15
    },
    "regex": "^(?=.{1,15}$)[^\\/\\\"\\[\\]:|<>+=;,?*@&_][^\\/\\\"\\[\\]:|<>+=;,?*@&]+[^\\/\\\"\\[\\]:|<>+=;,?*@&.-]$",
    "scope": "Resource group",
    "slug": "vmss",
    "dashes": true
  },
  {
    "name": "Virtual network",
    "group": "Networking",
    "length": {
      "min": 2,
      "max": 64
    },
    "regex": "^(?=.{2,64}$)[a-zA-Z0-9][a-zA-Z0-9-._]+[a-zA-Z0-9_]$",
    "scope": "Resource group",
    "slug": "vnet",
    "dashes": true
  },
  {
    "name": "Virtual network gateway",
    "group": "Networking",
    "length": {
      "min": 1,
      "max": 80
    },
    "regex": "^(?=.{1,80}$)[a-zA-Z0-9][a-zA-Z0-9-._]+[a-zA-Z0-9_]$",
    "scope": "Resource group",
    "slug": "vgw",
    "dashes": true
  },
  {
    "name": "Windows virtual machine",
    "group": "Compute and Web",
    "length": {
      "min": 1,
      "max": 15
    },
    "regex": "^(?=.{1,15}$)[^\\/\\\"\\[\\]:|<>+=;,?*@&_][^\\/\\\"\\[\\]:|<>+=;,?*@&]+[^\\/\\\"\\[\\]:|<>+=;,?*@&.-]$",
    "scope": "Resource group",
    "slug": "vm",
    "dashes": true
  },
  {
    "name": "Windows virtual machine scale set",
    "group": "Compute and Web",
    "length": {
      "min": 1,
      "max": 15
    },
    "regex": "^(?=.{1,15}$)[^\\/\\\"\\[\\]:|<>+=;,?*@&_][^\\/\\\"\\[\\]:|<>+=;,?*@&]+[^\\/\\\"\\[\\]:|<>+=;,?*@&.-]$",
    "scope": "Resource group",
    "slug": "vmss",
    "dashes": true
  }
];

export default resources;