import React from "react";
import { makeStyles } from "@material-ui/core";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";

const geoUrl =
  "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";

const useStyles = makeStyles((theme) => ({
  map: {
    display: "inline-block",
    verticalAlign: "middle",
  },
  geography: {
    fill: theme.palette.grey[100],
    stroke: theme.palette.grey[400],
  },
  markerPrimary: {
    fill: theme.palette.info.dark,
    stroke: theme.palette.info.main,
    strokeWidth: 1,
  },
  markerSecondary: {
    fill: theme.palette.error.main,
    stroke: theme.palette.error.light,
    strokeWidth: 1,
  },
}));

const MapChart = (props) => {
  const { locations } = props;
  const classes = useStyles();

  return (
    <React.Fragment>
      <ComposableMap
        className={classes.map}
        projection="geoMercator"
        projectionConfig={{
          scale: 130.0,
          center: [5.0, 15.0],
        }}
        width={800}
        height={400}
      >
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map((geo) => (
              <Geography
                key={geo.rsmKey}
                geography={geo}
                className={classes.geography}
              />
            ))
          }
        </Geographies>
        {locations.map((location) => (
          <Marker
            key={location.name}
            coordinates={location.coordinates}
            onMouseEnter={() => ReactTooltip.rebuild()}
            onMouseLeave={() => ReactTooltip.rebuild()}
            data-tip={location.name}
          >
            <circle
              r={5}
              className={
                location.recommended
                  ? classes.markerPrimary
                  : classes.markerSecondary
              }
            />
          </Marker>
        ))}
      </ComposableMap>
      <ReactTooltip type="info" />
    </React.Fragment>
  );
};

MapChart.propTypes = {
  locations: PropTypes.array.isRequired,
};

export default MapChart;
