import data from "../data/azure_tags";

const TagService = {
  getAll: () => {
    return data
      .map((t) => {
        t.id = t.name;
        return t;
      })
      .sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      });
  },
};

export default TagService;
