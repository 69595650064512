import { Grid, makeStyles } from "@material-ui/core";
import {
  GridToolbarContainer,
  GridColumnsToolbarButton,
  GridFilterToolbarButton,
  GridDensitySelector,
  GridToolbarExport,
} from "@material-ui/data-grid";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(4),
  },
  item: {
    position: "relative",
    backgroundColor: "#fff",
    borderRadius: "4px",
    "& button": {
      width: "100%",
      padding: theme.spacing(1),
      justifyContent: "left !important",
    },
  },
  chevron: {
    borderStyle: "solid",
    borderColor: theme.palette.primary.main,
    borderWidth: "0.20em 0.20em 0 0",
    content: "",
    display: "inline-block",
    width: "0.65em",
    height: "0.65em",
    position: "absolute",
    top: "50%",
    right: "1.25em",
    transform: "translate(0, -75%) rotate(135deg)",
    verticalAlign: "top",
  },
}));

const DataGridToolbar = () => {
  const classes = useStyles();

  return (
    <GridToolbarContainer className={classes.root}>
      <Grid container justify="space-between" spacing={4}>
        <Grid xs={12} sm={6} md={3} item>
          <div className={classes.item}>
            <GridColumnsToolbarButton />
            <span className={classes.chevron} />
          </div>
        </Grid>
        <Grid xs={12} sm={6} md={3} item>
          <div className={classes.item}>
            <GridFilterToolbarButton />
            <span className={classes.chevron} />
          </div>
        </Grid>
        <Grid xs={12} sm={6} md={3} item>
          <div className={classes.item}>
            <GridDensitySelector />
            <span className={classes.chevron} />
          </div>
        </Grid>
        <Grid xs={12} sm={6} md={3} item>
          <div className={classes.item}>
            <GridToolbarExport />
            <span className={classes.chevron} />
          </div>
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );
};

export default DataGridToolbar;
