import React, { useEffect } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router";
import { Container, makeStyles } from "@material-ui/core";
import TabPanel from "../../components/TabPanel";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import About from "./About";
import Locations from "./Locations";
import Resources from "./Resources";
import Tagging from "./Tagging";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  main: {
    flexGrow: 2,
  },
}));

const tabs = [
  { label: "About", path: "/", content: <About /> },
  { label: "Locations", path: "/locations", content: <Locations /> },
  { label: "Resources", path: "/resources", content: <Resources /> },
  { label: "Tagging", path: "/tagging", content: <Tagging /> },
];

const Azure = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { path } = useRouteMatch();
  const [activeTab, setActiveTab] = React.useState(
    Math.max(
      tabs.findIndex((t) => t.path === location.pathname),
      0
    )
  );

  useEffect(() => {
    setActiveTab(
      Math.max(
        tabs.findIndex((t) => t.path === location.pathname),
        0
      )
    );
  }, [location]);

  useEffect(() => {
    history.push(path.replace(/\/$/, "") + tabs[activeTab].path);
  }, [activeTab, history, path]);

  const handleTabChange = (event, newTab) => {
    setActiveTab(newTab);
  };

  return (
    <div className={classes.root}>
      <Header
        tabs={tabs}
        activeTab={activeTab}
        handleTabChange={handleTabChange}
      />
      <Container component="main" maxWidth="lg" className={classes.main}>
        {tabs.map((tab, index) => (
          <TabPanel
            key={`tab-content-${index}`}
            index={index}
            value={activeTab}
          >
            {tab.content}
          </TabPanel>
        ))}
      </Container>
      <Footer />
    </div>
  );
};

export default Azure;
