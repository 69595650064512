import {
  AppBar,
  Container,
  Grid,
  makeStyles,
  Tab,
  Tabs,
} from "@material-ui/core";
import AzToolsLogo from "../images/aztools.svg";

const useStyles = makeStyles((theme) => ({
  logo: {
    width: 80,
    height: 80,
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(8),
    marginBottom: theme.spacing(1),
  },
  tabs: {
    "& .MuiTab-root": {
      paddingBottom: theme.spacing(1),
    },
    "& .MuiTabs-indicator": {
      height: theme.spacing(0.75),
      backgroundColor: theme.palette.primary.contrastText,
    },
  },
}));

const Header = (props) => {
  const classes = useStyles();

  return (
    <AppBar component="header" position="static">
      <Container>
        <Grid container style={{ alignItems: "flex-end" }}>
          <Grid item>
            <img src={AzToolsLogo} alt="nitor" className={classes.logo} />
          </Grid>
          <Grid item>
            <Tabs
              className={classes.tabs}
              value={props.activeTab}
              onChange={props.handleTabChange}
              aria-label="things tabs"
            >
              {props.tabs.map((tab, index) => (
                <Tab
                  id={`tab-${index}`}
                  key={`tab-${index}`}
                  aria-controls={`tab-panel-${index}`}
                  label={tab.label}
                />
              ))}
            </Tabs>
          </Grid>
        </Grid>
      </Container>
    </AppBar>
  );
};

export default Header;
