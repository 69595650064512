import data from "../data/azure_locations";

const formatted = data
  .map((location) => {
    let code, code2, code3, code4;
    let parts = location.displayName
      .split(/\s+/)
      .map((p) => p.toLowerCase())
      .map((p) => p.replace(/[^a-zA-Z0-9]/, ""));

    if (parts.length === 1) {
      code = parts[0].substring(0, 4);
      code2 = "-";
      code3 = parts[0].substring(0, 2);
      code4 = "-";
    }
    if (parts.length === 2) {
      code = parts[0].substring(0, 2) + parts[1].substring(0, 2);
      code2 = parts[1].substring(0, 2) + parts[0].substring(0, 2);
      code3 = parts[0].substring(0, 1) + parts[1].substring(0, 1);
      code4 = parts[1].substring(0, 1) + parts[0].substring(0, 1);
    }
    if (parts.length === 3) {
      code =
        parts[0].substring(0, 1) +
        parts[1].substring(0, parts[2].length > 1 ? 1 : 2) +
        parts[2].substring(0, 2);
      code2 =
        parts[2].length > 1
          ? parts[2].substring(0, 2) +
            parts[1].substring(0, 1) +
            parts[0].substring(0, 1)
          : parts[1].substring(0, 2) +
            parts[0].substring(0, 1) +
            parts[2].substring(0, 1);
      code3 =
        parts[0].substring(0, 1) +
        parts[1].substring(0, 1) +
        parts[2].substring(0, 1);
      code4 =
        parts[2].length > 1
          ? parts[2].substring(0, 1) +
            parts[1].substring(0, 1) +
            parts[0].substring(0, 1)
          : parts[1].substring(0, 1) +
            parts[0].substring(0, 1) +
            parts[2].substring(0, 1);
    }
    if (parts.length === 4) {
      code =
        parts[0].substring(0, 1) +
        parts[1].substring(0, 1) +
        parts[2].substring(0, 1) +
        parts[3].substring(0, 1);
      code2 =
        parts[3].substring(0, 1) +
        parts[2].substring(0, 1) +
        parts[1].substring(0, 1) +
        parts[0].substring(0, 1);
      code3 = "-";
      code4 = "-";
    }

    return {
      id: location.id,
      group: location.metadata.geographyGroup,
      name: location.displayName,
      location: location.name,
      code: {
        full: location.name,
        normal: code,
        normalAlt: code2,
        short: code3,
        shortAlt: code4,
      },
      recommended: location.metadata.regionCategory === "Recommended",
      coordinates: [location.metadata.longitude, location.metadata.latitude],
    };
  })
  .sort((a, b) => {
    if (a.name > b.name) return 1;
    if (a.name < b.name) return -1;
    return 0;
  });

const LocationService = {
  getAll: () => {
    return formatted;
  },
  getRecommended: () => {
    return formatted.filter((location) => location.recommended);
  },
};

export default LocationService;
